import Splide from '@splidejs/splide';
window.Splide = Splide;

import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';
import '@fortawesome/fontawesome-free/js/fontawesome';

import 'bootstrap';

window.fslightbox = require('fslightbox');

Splide.defaults = {
    mediaQuery: 'min',
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    perPage: 1,
    direction: document.querySelector('html').getAttribute('dir'),
};

const sliders = {};

if (document.querySelector('.hero-slider')) {
    sliders.hero = new Splide('.hero-slider', {
        autoplay: false,
        arrows: false,
        type: 'fade',
        rewind: 'true',
        speed: 1500,
    });

    sliders.hero.mount();
}

if (document.querySelector('.product-slider')) {
    const pslider = new Splide('.product-slider', {
        perPage: 1,
        type: 'loop',
        pagination: false,
        autoplay: false,
        arrows: true,
        gap: 0,
        breakpoints: {
            576: {
                perPage: 2,
            },

            768: {
                perPage: 3
            },

            1024: {
                perPage: 4,
            }
        }
    }).mount();
}

if (document.querySelector('.cat-prod-slider')) {
    document.querySelectorAll('.cat-prod-slider').forEach(slider => {
        let catProdSlider = new Splide(slider, {
            perPage: 1,
            // type: 'loop',
            // focus: 'center',
            arrows: false,
            pagination: false,
            autoplay: false,
            gap: 20,
            breakpoints: {
                576: {
                    perPage: 2,
                },

                768: {
                    perPage: 3
                },

                1024: {
                    perPage: 4,
                }
            }
        }).mount();
    });
}

if (document.querySelector('.tm-slider')) {
    sliders.tm = new Splide('.tm-slider', {
        autoplay: false,
        arrows: false,
        type: 'loop',
        // pagination: false,
        // focus: 'center',
        gap: 0,
        breakpoints: {
            768: {
                perPage: 2,
            },
        }
    });

    sliders.tm.mount();
}

if (document.querySelector('.product-single-page')) {
    let main = new Splide('#prod-main-slider', {
        type: 'fade',
        rewind: true,
        pagination: false,
        // arrows: false,
        autoplay: false,
    });

    let thumbs = new Splide('#prod-thumbnail-slider', {
        fixedWidth: 100,
        fixedHeight: 100,
        gap: 10,
        rewind: true,
        pagination: false,
        isNavigation: true,
        arrows: false,
        autoplay: false,
        dragMinThreshold: 20,
    });

    main.sync(thumbs);
    main.mount();
    thumbs.mount();
}

window.startCounter = () => {
    if (document.querySelector('.counter')) {
        let counters = document.querySelectorAll('.counter');
        for(let n of counters) { // Code by https://github.com/lukaofirst/animated-counter
            const updateCount = () => {
                const target = + n.getAttribute('data-target');
                const count = + n.innerText;
                const speed = 5000;

                const inc = target / speed;

                if(count < target) {
                    n.innerText = Math.ceil(count + inc);
                    setTimeout(updateCount, 10);
                } else {
                    n.innerText = target;
                }
            }

            updateCount();
        }
    }
}

startCounter();

if (document.querySelector('.catmenu')) {
    document.querySelector('.catmenu > a').addEventListener('click', (e) => {
        e.preventDefault();
        e.target.closest('.catmenu').classList.toggle('active');
    });
}

if (document.querySelectorAll('.sidebar')) {
    let sidebars = document.querySelectorAll('.sidebar');

    sidebars.forEach(sidebar => {
        sidebar.querySelector('button.close').addEventListener('click', e => {
            e.target.closest('.sidebar').classList.remove('open');
        });
    });
}

if ( document.querySelector('.sidebar-overlay') ) {
    document.querySelector('.sidebar-overlay').addEventListener('click', e => {
        if (document.querySelector('.sidebar.open')) {
            document.querySelector('.sidebar.open').querySelector('button.close').click();
        }
    });
}

if (document.querySelector('.stickymenu')) {
    let items = document.querySelectorAll('.stickymenu .item[data-sidebar]');

    items.forEach(item => {
        item.addEventListener('click', e => {
            e.preventDefault();

            let menu = e.target.closest('.item');
            let sidebar = `.sidebar.${menu.dataset.sidebar}`;
            document.querySelector(sidebar).classList.add('open');
        });
    })
}

if (document.querySelector('.topmenu')) {
    let menu = document.querySelector('nav.menu');
    let scontent = document.querySelector('.sidebar-menu .sidebar-content');
    scontent.innerHTML = menu.outerHTML;

    scontent.querySelector('.topmenu').className = 'topmenu-m';

    let toggleElems = document.querySelectorAll('.topmenu-m li a + .sub-menu');
    toggleElems.forEach(el => {
        el.previousElementSibling.insertAdjacentHTML('beforeend', '<button class="btn-transparent btn-icon drop-toggle"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 9.5L7 14.5H17L12 9.5Z" fill="currentColor"></path></svg></button>');
    });

    let toggles = document.querySelectorAll('button.drop-toggle');
    toggles.forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();

            e.target.closest('a').classList.toggle('active');
        });
    });

}

if (document.querySelector('[data-setbg]')) {
    let elems = document.querySelectorAll('[data-setbg]');
    elems.forEach(elem => {
        elem.style.backgroundImage = `url(${elem.dataset.setbg})`;
    });
}

if (document.querySelector('.accordion')) {
    window.accordionInit = () => {
        let aHead = document.querySelectorAll('.accordion-head');
        aHead.forEach(el => {
            el.addEventListener('click', (e) => {
                let state = false;

                state = (el.classList.contains('shown')) ? true : false;

                let container = e.target.closest('.accordion');

                if (container.querySelector('.accordion-item.expanded')) {
                    container.querySelector('.accordion-item.expanded').classList.remove('expanded');
                }

                if (container.querySelector('.accordion-head.shown')) {
                    container.querySelector('.accordion-head.shown').classList.remove('shown');
                }

                if (el.classList.contains('shown')) {
                    el.classList.toggle('shown');
                    el.closest('.accordion-item').classList.remove('expanded');
                }

                if (!el.classList.contains('shown')) {
                    el.classList.toggle('shown');
                    el.closest('.accordion-item').classList.add('expanded');
                }

                if (state) {
                    el.classList.remove('shown');
                    el.closest('.accordion-item').classList.remove('expanded');
                }
            });
        });
    };

    accordionInit();
}

function stickyHeader() {
    let header = document.querySelector('header');

    const offset = 60;

    if (document.body.scrollTop > offset || document.documentElement.scrollTop > offset) {
        header.classList.add('sticky');
        // document.querySelector(':root').style.setProperty('--header-h', '60px');
    }
    else {
        header.classList.remove('sticky');
        // document.querySelector(':root').style.setProperty('--header-h', '100px');
    }
}

if (document.querySelector('#topenquiremodal')) {
    let modal = document.querySelector('#topenquiremodal');
    modal.addEventListener('show.bs.modal', e => {
        const button = e.relatedTarget;
        const recipient = button.getAttribute('data-name');

        const modalTitle = modal.querySelector('.modal-title');
        const modalBodyInput = modal.querySelector('.modal-body input');

        let form = modal.querySelector('form');

        if (recipient) {
            modalTitle.textContent = `Enquiry for ${recipient}`;
            if (form.querySelector('input[name="product"]')) {
                form.querySelector('input[name="product"]').remove();
            }
            form.insertAdjacentHTML('afterbegin', `<input type="hidden" value="${recipient}" name="product">`);
        }
        else {
            modalTitle.textContent = `${modalTitle.getAttribute('data-default-title')}`;
            if (form.querySelector('input[name="product"]')) {
                form.querySelector('input[name="product"]').remove();
            }
        }
    });
}

window.addEventListener('scroll', () => {
    stickyHeader();
});

import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

if (document.querySelector('input[name="phone"]')) {
    let inputs = document.querySelectorAll('input[name="phone"]');

    inputs.forEach(input => {
        intlTelInput(input, {
            nationalMode: false,
        });
    });
}


